import { Form, Input, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import DatePicker from '~UI/DatePicker';
import TimePicker from '~UI/TimePicker';
import { warehouseAdjustPrincipalAction } from '~Warehouses/actions';

interface IState {
    aNotes: number;
    amount: number;
    capacityLimit: number,
    description: string;
    principalDraw: number;
    sellerNotes: number;
    transactionDateTime: string;
    xNotes: number;
}

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
    warehouseUuid: string;
}

interface IPropsDispatch {
    adjustPrincipal: (
        aNotes: number,
        amount: number,
        capacityLimit: number,
        principalDraw: number,
        sellerNotes: number,
        description: string,
        transactionTime: string,
        xNotes: number,
    ) => void;
}

type Props = IProps  & IPropsDispatch;

class PrincipalModal extends React.Component<Props, IState> {
    public state: IState = {
        aNotes: null,
        amount: null,
        capacityLimit: null,
        description: null,
        principalDraw: null,
        sellerNotes: null,
        transactionDateTime: null,
        xNotes: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAnotes = this.onChangeAnotes.bind(this);
        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onChangeCapacityLimit = this.onChangeCapacityLimit.bind(this);
        this.onChangePrincipalDraw = this.onChangePrincipalDraw.bind(this);
        this.onChangeSellerNotes = this.onChangeSellerNotes.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeTransactionDate = this.onChangeTransactionDate.bind(this);
        this.onChangeTransactionTime = this.onChangeTransactionTime.bind(this);
        this.onChangeXnotes = this.onChangeXnotes.bind(this);

        this.onClickCancel = this.onClickCancel.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const {
            aNotes,
            amount,
            capacityLimit,
            description,
            principalDraw,
            sellerNotes,
            transactionDateTime,
            xNotes,
        } = this.state;

        const transactionDateTimeDayJs: Dayjs = transactionDateTime ? dayjs(transactionDateTime) : null;

        return (
            <Modal
                okText='Record Adjustment'
                onCancel={this.onClickCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Adjust Principal'
                wrapClassName='warehouse-adjust-principal-modal'
            >
                <Form.Item label='Transaction Date' className='transaction-date'>
                    <Space>
                        <DatePicker className='transaction-pick-date' onChange={this.onChangeTransactionDate} value={transactionDateTimeDayJs} format='DD/MM/YYYY' />
                        <TimePicker className='transaction-pick-time' onChange={this.onChangeTransactionTime} value={transactionDateTimeDayJs} format='H:mm' />
                    </Space>
                </Form.Item>
                <Form.Item label='Class A Notes' className='a-notes'>
                    <Input addonBefore='$' onChange={this.onChangeAnotes} type='number' value={aNotes} />
                </Form.Item>
                <Form.Item label='Class B Notes' className='amount'>
                    <Input addonBefore='$' onChange={this.onChangeAmount} type='number' value={amount} />
                </Form.Item>
                <Form.Item label='Class X Notes' className='x-notes'>
                    <Input addonBefore='$' onChange={this.onChangeXnotes} type='number' value={xNotes} />
                </Form.Item>
                <Form.Item label='Capacity Limit' className='capacity-limit'>
                    <Input addonBefore='$' onChange={this.onChangeCapacityLimit} type='number' value={capacityLimit} />
                </Form.Item>
                <Form.Item label='Principal Draw' className='principal-draw'>
                    <Input addonBefore='$' onChange={this.onChangePrincipalDraw} type='number' value={principalDraw} />
                </Form.Item>
                <Form.Item label='Seller Notes' className='seller-notes'>
                    <Input addonBefore='$' onChange={this.onChangeSellerNotes} type='number' value={sellerNotes} />
                </Form.Item>
                <Form.Item label='Description' className='description'>
                    <Input onChange={this.onChangeDescription} value={description} />
                </Form.Item>
            </Modal>
        );
    }

    private onChangeAnotes(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9.-]/.test(event.target.value)) {
            return;
        }

        this.setState({
            aNotes: event.target.value === '' ? null : event.target.valueAsNumber,
        });
    }

    private onChangeAmount(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9.-]/.test(event.target.value)) {
            return;
        }

        this.setState({
            amount: event.target.value === '' ? null : event.target.valueAsNumber,
        });
    }

    private onChangeCapacityLimit(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9.-]/.test(event.target.value)) {
            return;
        }

        this.setState({
            capacityLimit: event.target.value === '' ? null : event.target.valueAsNumber,
        });
    }

    private onChangePrincipalDraw(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9.-]/.test(event.target.value)) {
            return;
        }

        this.setState({
            principalDraw: event.target.value === '' ? null : event.target.valueAsNumber,
        });
    }

    private onChangeSellerNotes(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9.-]/.test(event.target.value)) {
            return;
        }

        this.setState({
            sellerNotes: event.target.value === '' ? null : event.target.valueAsNumber,
        });
    }

    private onChangeDescription(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            description: event.target.value,
        });
    }

    private onChangeTransactionDate(date: Dayjs) {
        this.setState({
            transactionDateTime: date ? date.format('YYYY-MM-DD') : null,
        });
    }

    private onChangeTransactionTime(time: Dayjs) {
        const { transactionDateTime } = this.state;

        this.setState({
            transactionDateTime: dayjs(`${dayjs(transactionDateTime || undefined).format('YYYY-MM-DD')} ${time.format('HH:mm')}`).format(),
        });
    }

    private onChangeXnotes(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9.-]/.test(event.target.value)) {
            return;
        }

        this.setState({
            xNotes: event.target.value === '' ? null : event.target.valueAsNumber,
        });
    }

    private onClickCancel() {
        this.setState({
            aNotes: null,
            amount: null,
            capacityLimit: null,
            description: null,
            principalDraw: null,
            sellerNotes: null,
            transactionDateTime: dayjs().format(),
            xNotes: null,
        });
        this.props.onCancel();
    }

    private onClickOk() {
        const {
            aNotes,
            amount,
            capacityLimit,
            description,
            principalDraw,
            sellerNotes,
            transactionDateTime,
            xNotes,
        } = this.state;

        const transactionDateTimeDayJs: Dayjs = transactionDateTime ? dayjs(transactionDateTime) : null;
        if (!transactionDateTimeDayJs) {
            return;
        }

        this.props.adjustPrincipal(
            aNotes,
            amount,
            capacityLimit,
            principalDraw,
            sellerNotes,
            description,
            transactionDateTimeDayJs.format('YYYY-MM-DDTHH:mm:ssZ'),
            xNotes,
        );
        this.onClickCancel();
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        adjustPrincipal: (
            aNotes: number,
            amount: number,
            capacityLimit: number,
            principalDraw: number,
            sellerNotes: number,
            description: string,
            transactionDateTime: string,
            xNotes: number,
        ) => dispatch(warehouseAdjustPrincipalAction(
            ownProps.warehouseUuid,
            aNotes,
            amount,
            capacityLimit,
            principalDraw,
            sellerNotes,
            description,
            transactionDateTime,
            xNotes,
        )),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(PrincipalModal);
