import { fetchDelete, fetchGet, fetchPost, fetchPostFile, fetchPut } from '~utilities/fetch';
import ILoan from './ILoan';
import ILoanFee from './ILoanFee';
import ILoanGracePeriod from './ILoanGracePeriod';
import ILoanNote from './ILoanNote';
import ILoanPayoutFigure from './ILoanPayoutFigure';
import ILoanPayoutFigureItem from './ILoanPayoutFigureItem';
import ILoanPayoutFigureSection from './ILoanPayoutFigureSection';
import MaturityReminderStatusEnum from './MaturityReminderStatusEnum';
import {
    transformLoan,
    transformLoanFee,
    transformLoanGracePeriod,
    transformLoanNote,
    transformLoanPayoutFigure,
    transformLoanPayoutFigureItem,
    transformLoanPayoutFigureSection,
} from './transformers';
import ListSortOrderEnum from './ListSortOrderEnum';
import LoanStatusEnum from './LoanStatusEnum';

export async function loansDashboardRequest(): Promise<Response> {
    return fetchGet('/loans/dashboard');
}

export async function loansDischargeForecastRequest(): Promise<Response> {
    return fetchGet('/loans/discharge-forecast');
}

export async function loansDrawdownsRequest(): Promise<Response> {
    return fetchGet('/loans/drawdowns');
}

export async function loansListRequest(): Promise<Response> {
    return fetchGet('/loans');
}

export async function loansPaginatedListRequest(
    page: number,
    perPage: number,
    orderBy: string,
    order: ListSortOrderEnum,
    statuses: LoanStatusEnum[],
    administratorUuids: string[],
    code: string,
    name: string,
): Promise<Response> {
    return fetchGet('/loans' , {
        administratorUuids,
        code,
        name,
        order,
        orderBy,
        page,
        perPage,
        statuses,
    });
}

export async function loansSearchRequest(keyword: string): Promise<Response> {
    return fetchPost('/loans/search', { keyword });
}

export async function loanActiveRequest(uuid: string): Promise<Response> {
    return fetchPost(`/loans/${uuid}/active`);
}

export async function loanAssignRequest(loanUuid: string, administratorUuid: string): Promise<Response> {
    return fetchPost(`/loans/${loanUuid}/assign`, {
        administratorUuid,
    });
}

export async function loanDischargeRequest(uuid: string, dischargeDate: string): Promise<Response> {
    return fetchPost(`/loans/${uuid}/discharge`, {
        dischargeDate,
    });
}

export async function loanDischargeDateRequest(uuid: string, dischargeDate: string): Promise<Response> {
    return fetchPost(`/loans/${uuid}/discharge-date`, {
        dischargeDate,
    });
}

export async function loanDocumentsAddRequest(uuid: string, file: File): Promise<Response> {
    return fetchPostFile(`/loans/${uuid}/documents`, file);
}

export async function loanDocumentsListRequest(uuid: string): Promise<Response> {
    return fetchGet(`/loans/${uuid}/documents`);
}

export async function loanDocumentDeleteRequest(documentUuid: string): Promise<Response> {
    return fetchDelete(`/loan-documents/${documentUuid}`);
}

export async function loanDrawdownRequest(uuid: string): Promise<Response> {
    return fetchPost(`/loans/${uuid}/drawdown`);
}

export async function loanExtensionRequest(uuid: string): Promise<Response> {
    return fetchPost(`/loans/${uuid}/extension`);
}

export async function loanFeesAddRequest(uuid: string, fee: ILoanFee): Promise<Response> {
    return fetchPost(`/loans/${uuid}/fees`, transformLoanFee(fee));
}

export async function loanFeesListRequest(uuid: string): Promise<Response> {
    return fetchGet(`/loans/${uuid}/fees`);
}

export async function loanFeeDeleteRequest(feeUuid: string): Promise<Response> {
    return fetchDelete(`/loan-fees/${feeUuid}`);
}

export async function loanFeeUpdateRequest(fee: ILoanFee): Promise<Response> {
    return fetchPost(`/loan-fees/${fee.uuid}`, transformLoanFee(fee));
}

export async function loanFundRequest(uuid: string, warehouseUuid: string, investorAccountUuid: string): Promise<Response> {
    return fetchPost(`/loans/${uuid}/fund`, {
        investorAccountUuid,
        warehouseUuid,
    });
}

export async function loanGetRequest(uuid: string): Promise<Response> {
    return fetchGet(`/loans/${uuid}`);
}

export async function loanGracePeriodRequest(uuid: string): Promise<Response> {
    return fetchPost(`/loans/${uuid}/grace-period`);
}

export async function loanGracePeriodsAddRequest(loanUuid: string, gracePeriod: ILoanGracePeriod): Promise<Response> {
    return fetchPost(`/loans/${loanUuid}/grace-periods`, transformLoanGracePeriod(gracePeriod));
}

export async function loanGracePeriodsListRequest(loanUuid: string): Promise<Response> {
    return fetchGet(`/loans/${loanUuid}/grace-periods`);
}

export async function loanHistoriesListRequest(uuid: string): Promise<Response> {
    return fetchGet(`/loans/${uuid}/history`);
}

export async function loanNotesAddRequest(loanUuid: string, note: ILoanNote): Promise<Response> {
    return fetchPost(`/loans/${loanUuid}/notes`, transformLoanNote(note));
}

export async function loanNotesListRequest(uuid: string): Promise<Response> {
    return fetchGet(`/loans/${uuid}/notes`);
}

export async function loanRecordNextPaymentRequest(loan: ILoan): Promise<Response> {
    return fetchPost(`/loans/${loan.uuid}/record-next-payment`, {
        loanAmount: loan.amount,
        loanPaymentAmountNext: loan.paymentAmountNext,
        loanPaymentDateNext: loan.paymentDateNext,
    });
}

export async function loanPayoutFigureApproveRequest(payoutFigureUuid: string): Promise<Response> {
    return fetchPost(`/loan-payout-figures/${payoutFigureUuid}/approve`);
}

export async function loanPayoutFigureDeleteRequest(payoutFigureUuid: string): Promise<Response> {
    return fetchDelete(`/loan-payout-figures/${payoutFigureUuid}`);
}

export async function loanPayoutFigureGetRequest(payoutFigureUuid: string): Promise<Response> {
    return fetchGet(`/loan-payout-figures/${payoutFigureUuid}`);
}

export async function loanPayoutFigureProcessRequest(payoutFigureUuid: string, force: boolean = false): Promise<Response> {
    return fetchPost(`/loan-payout-figures/${payoutFigureUuid}/process-payout`, null, { force });
}

export async function loanPayoutFigureUpdateRequest(loanPayoutFigure: ILoanPayoutFigure): Promise<Response> {
    return fetchPut(`/loan-payout-figures/${loanPayoutFigure.uuid}`, transformLoanPayoutFigure(loanPayoutFigure));
}

export async function loanPayoutFigureItemAddRequest(item: ILoanPayoutFigureItem): Promise<Response> {
    return fetchPost('/loan-payout-figure-items', transformLoanPayoutFigureItem(item));
}

export async function loanPayoutFigureItemDeleteRequest(itemUuid: string): Promise<Response> {
    return fetchDelete(`/loan-payout-figure-items/${itemUuid}`);
}

export async function loanPayoutFigureItemUpdateRequest(loanPayoutFigureItem: ILoanPayoutFigureItem): Promise<Response> {
    return fetchPut(`/loan-payout-figure-items/${loanPayoutFigureItem.uuid}`, transformLoanPayoutFigureItem(loanPayoutFigureItem));
}

export async function loanPayoutFigureSectionAddRequest(loanPayoutFigureSection: ILoanPayoutFigureSection): Promise<Response> {
    return fetchPost('/loan-payout-figure-sections', transformLoanPayoutFigureSection(loanPayoutFigureSection));
}

export async function loanPayoutFigureSectionDeleteRequest(sectionUuid: string): Promise<Response> {
    return fetchDelete(`/loan-payout-figure-sections/${sectionUuid}`);
}

export async function loanPayoutFiguresAddRequest(payoutFigure: ILoanPayoutFigure): Promise<Response> {
    return fetchPost('/loan-payout-figures', transformLoanPayoutFigure(payoutFigure));
}

export async function loanPayoutFiguresListRequest(loanUuid: string): Promise<Response> {
    return fetchGet(`/loans/${loanUuid}/payout-figures`);
}

export async function loanPrincipalReductionRequest(
    loanUuid: string,
    amount: number,
    transactionTime: string,
): Promise<Response> {
    return fetchPost(`/loans/${loanUuid}/principal-reduction`, {
        amount,
        transactionTime,
    });
}

export async function loanRecordDrawdownRequest(
    loanUuid: string,
    retainedContingency: number,
    retainedFunds: number,
    transactionTime: string,
): Promise<Response> {
    return fetchPost(`/loans/${loanUuid}/record-drawdown`, {
        retainedContingency,
        retainedFunds,
        transactionTime,
    });
}

export async function loanRecoveryRequest(loanUuid: string): Promise<Response> {
    return fetchPost(`/loans/${loanUuid}/recovery`);
}

export async function loanSendMaturationReminderRequest(uuid: string, status: MaturityReminderStatusEnum): Promise<Response> {
    return fetchPost(`/loans/${uuid}/send-maturation-reminder`, {
        status,
    });
}

export async function loanSkipMaturationReminderRequest(uuid: string, status: MaturityReminderStatusEnum): Promise<Response> {
    return fetchPost(`/loans/${uuid}/skip-maturation-reminder`, {
        status,
    });
}

export async function loanTransactionsListRequest(loanUuid: string): Promise<Response> {
    return fetchGet(`/loans/${loanUuid}/transactions`);
}

export async function loanUpdateRequest(loan: ILoan): Promise<Response> {
    return fetchPut(`/loans/${loan.uuid}`, transformLoan(loan));
}

export async function loanWarehouseLoansListRequest(loanUuid: string): Promise<Response> {
    return fetchGet(`/loans/${loanUuid}/warehouse-loans`);
}
